export const FETCH_ALL_NOT_APPROVED_CLUBS = 'FETCH_ALL_NOT_APPROVED_CLUBS';
export const FETCH_ALL_APPROVED_CLUBS = 'FETCH_ALL_APPROVED_CLUBS';
export const FETCH_ALL_CLUB_DETAILS = 'FETCH_ALL_CLUB_DETAILS';

export const GET_SUCCESS = 'GET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const SUCCESS_REDIRECT = 'SUCCESS_REDIRECT';
export const GET_ERROR = 'GET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const GET_ALL_PENDING_PAYOUTS = 'GET_ALL_PENDING_PAYOUTS';
export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const GET_TRANSACTION_BY_ID = 'GET_TRANSACTION_BY_ID';

export const FILE_UPLOAD_SUCCESS='FILE_UPLOAD_SUCCESS'

// user types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';

// percentage types
export const FETCH_CLUB_PERCENTAGE = 'FETCH_CLUB_PERCENTAGE';
export const FETCH_CLUB_SALESPERSON_ID = 'FETCH_CLUB_SALESPERSON_ID';

export const FETCH_ALL_TYPES = 'FETCH_ALL_TYPES';

export const DISPLAY_SPINNER = 'DISPLAY_SPINNER';

// salesperson payouts
export const FETCH_ALL_SALESPERSONS = 'FETCH_ALL_SALESPERSONS';
export const FETCH_SALESPERSON_DETAILS = 'FETCH_SALESPERSON_DETAILS';
export const FETCH_SALESPERSON_PENDING = 'FETCH_SALESPERSON_PENDING';
export const FETCH_ALL_SALESPERSON_PAYMENTS = 'FETCH_ALL_SALESPERSON_PAYMENTS';
export const FETCH_SALESPERSON_PAYMENT_FILTER_DATES = 'FETCH_SALESPERSON_PAYMENT_FILTER_DATES';
export const FETCH_CLUB_PAYOUTS = 'FETCH_CLUB_PAYOUTS';


export const FETCH_DONATION_SUMMARY = 'FETCH_DONATION_SUMMARY'
export const FETCH_DONATION_SUMMARY_SALESPERSON= 'FETCH_DONATION_SUMMARY_SALESPERSON'
export const FETCH_DONATION_DISTRIBUTION = 'FETCH_DONATION_DISTRIBUTION'
export const FETCH_DONATION_DISTRIBUTION_SALESPERSON= 'FETCH_DONATION_DISTRIBUTION_SALESPERSON'

// Subscriptions
export const FETCH_CLUBS_SUBSCRIPTIONS = 'FETCH_CLUBS_SUBSCRIPTIONS';


export const GET_PRODUCT_TRANSACTIONS = 'GET_PRODUCT_TRANSACTIONS';