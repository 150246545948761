import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/reports/DashboardView';
import SalesperosnDashboardView from 'src/views/reports/DashboardView/salespersonDashboard';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import TypeListView from 'src/views/types/TypeListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import ClubListView from 'src/views/club/ClubListView';
import SubscriptionsView from 'src/views/club/ClubListView/Subscriptions';
import ClubDetailView from 'src/views/club/ClubListView/clubDetailView';
import PayoutListview from 'src/views/donation/DonationListview';
import SalespersonsListView from 'src/views/salesperson/SalespersonsListView';
import SalespersonPaymentsView from 'src/views/salesperson/payments';
import TransactionListView from './views/transaction/TransactionListView';
import SalepersonDetailView from './views/salesperson/SalespersonDetailView';
import TransactionDetailView from './views/transaction/TransactionDetailView';
import FileExportView from 'src/views/file/FileExportView';


const routes = (isAdmin, isSalesperson) => [
  {
    path: 'app',
    element: isAdmin ? <DashboardLayout /> : <Navigate to="/" />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'types', element: <TypeListView /> },
      { path: 'transactions', element: <TransactionListView /> },
      { path: 'clubs', element: <ClubListView /> },
      { path: 'subscriptions', element: <SubscriptionsView /> },
      { path: 'payouts', element: <PayoutListview /> },
      { path: 'salespersons', element: <SalespersonsListView /> },
      { path: 'salesperson-payments', element: <SalespersonPaymentsView /> },
      { path: 'salespersons/:id/view', element: <SalepersonDetailView /> },
      { path: 'clubs/:id/view', element: <ClubDetailView /> },
      { path: 'transactions/:id/view', element: <TransactionDetailView /> },
      { path: 'files', element: <FileExportView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'salesperson',
    element: isSalesperson ? <DashboardLayout /> : <Navigate to="/" />,
    children: [
      { path: 'dashboard', element: <SalesperosnDashboardView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <LoginView />,
    children: []
  },
  {
    path: '/admin',
    element: !isAdmin && !isSalesperson ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
