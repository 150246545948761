import axios from 'axios';
import { baseUrl, authHeader,multipartAuthHeader } from 'src/utils/helper';
import
{
  GET_ALL_PENDING_PAYOUTS, GET_ALL_TRANSACTIONS,
  GET_TRANSACTION_BY_ID, FETCH_CLUB_PAYOUTS, FILE_UPLOAD_SUCCESS,
  FETCH_DONATION_SUMMARY,FETCH_DONATION_SUMMARY_SALESPERSON, FETCH_DONATION_DISTRIBUTION, FETCH_DONATION_DISTRIBUTION_SALESPERSON,
  GET_PRODUCT_TRANSACTIONS
} from 'src/utils/types';
import { displaySuccess, clearSuccess } from './formResponse';
import { refreshToken } from './userActions';

export const getAllPendingPayouts = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/pending-payouts`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: GET_ALL_PENDING_PAYOUTS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const clubPayout = (data, onsuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/payouts`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Payout done successfully'));
          setTimeout(() => {
            dispatch(clearSuccess());
            dispatch(getAllPendingPayouts({
              fromDate: data.fromDate,
              toDate: data.toDate,
            }));
            onsuccess(response.data);
          }, 3500);
        }
      }
    } catch (error) {
      onsuccess();
      console.error('there is an error', error);
    }
  };
};

export const getClubTransactions = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/transaction-list`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: GET_ALL_TRANSACTIONS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubTransactionById = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/transaction-details`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: GET_TRANSACTION_BY_ID,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubPayoutHistory = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/payouts/payout-details`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_PAYOUTS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const updateTwintPayoutsFile = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();

      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/payouts/twint-payout-file`, data, multipartAuthHeader());
        if (response && response.status === 200) {
          console.log('File upload status: ', response.status);
          dispatch(displaySuccess('Twint payout file uploaded Successfully !'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
          dispatch({
            type: FILE_UPLOAD_SUCCESS,
            payload: response.status
          });
          alert('File uploaded successfully!');
        } else {
          alert(`File upload failed: ${response.data.message || 'Unknown error'}`);
        }
      }
    } catch (error) {
      console.error('There is an error', error);
      let errorMessage = 'File upload failed!';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      alert(errorMessage);
    }
  };
};

export const getDonationSummary = () => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.get(`${baseUrl}/donations/donation-summary`, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_DONATION_SUMMARY,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const getDonationSummaryForSalesPerson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/donation-summary-salesperson`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_DONATION_SUMMARY_SALESPERSON,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const getDonationDistribution = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/donation-distribution`,data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_DONATION_DISTRIBUTION,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const getDonationDistributionSalesperson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/donation-distribution-salesperson`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_DONATION_DISTRIBUTION_SALESPERSON,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getProductTransactions = (productId, pageId = 0, pageSize = 10) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(
          `${baseUrl}/donations/product-transactions`,
          {
            productId,
            pageId,
            pageSize
          },
          authHeader()
        );

        if (response && response.data) {
          dispatch({
            type: GET_PRODUCT_TRANSACTIONS,
            payload: response.data
          });
          return response.data;
        }
      }
    } catch (error) {
      console.error('Error fetching product transactions:', error);
      return null;
    }
  };
};